import React, { FC } from 'react'
import Layout from '../components/layouts/layout'
import desktopBackgroundImage from '../images/services/banner-services-desktop.jpg'
import mobileBackgroundImage from '../images/services/banner-services-mobile.jpg'
import { Hero } from '../components/hero/hero'
import { Colors } from '../colors'
import { useLayoutInformation } from '../hooks/useLayoutInformation'
import { AssurancesBox } from '../components/services/assurances'
import Button from '../components/button/button'
import { Routes } from '../routes'
import * as styles from './services.module.scss'
import { PageRow } from '../components/page-row/page-row'
import { PlacementBox } from '../components/services/placements'
import { LiquiditesBox } from '../components/services/liquidites'
import { AutresBox } from '../components/services/autres'
import ColorStripe from '../components/blocs/color-stripe'
import Contact from '../components/blocs/contact'
import waveBackground from '../images/bg-wave-light-lightaus-blue.png'
import { FinancementHypothecaireBox } from '../components/services/financement-hypothecaire'

export const Services: FC = () => {
    const { isMobile, secondaryColor } = useLayoutInformation(Colors.lightausBlue)

    return (
        <Layout titleIntlId="Services">
            <Hero
                backgroundImageUri={isMobile ? mobileBackgroundImage : desktopBackgroundImage}
                message="Découvrez nos services de gestion financière, d’assurance et de gestion de liquidités"
                buttonColor={secondaryColor}
            />
            <PageRow>
                <div className={styles.services}>
                    <div className={styles.serviceDescription}>
                        <FinancementHypothecaireBox />
                        <div>
                            <p>Vous souhaitez faire l'acquisition d'une nouvelle propriété ou faire un renouvellement hypothécaire?</p>
                            <Button text="En savoir plus" linkTo={Routes.financementHypothecaire} />
                        </div>
                    </div>
                    <div className={styles.serviceDescription}>
                        <AssurancesBox />
                        <div>
                            <p>Vous souhaitez sécuriser votre avenir financier tout en protégeant vos proches?</p>
                            <Button text="En savoir plus" linkTo={Routes.assuranceDePersonnes} />
                        </div>
                    </div>
                    <div className={styles.serviceDescription}>
                        <PlacementBox />
                        <div>
                            <p>
                                Vous avez des rêves pour l’avenir et vous voulez garder le cap en vous assurant d’avoir
                                de bonnes habitudes financières pour les réaliser?
                            </p>
                            <Button text="En savoir plus" linkTo={Routes.placements} />
                        </div>
                    </div>
                    <div className={styles.serviceDescription}>
                        <LiquiditesBox />
                        <div>
                            <p>
                                Vous avez besoin de financement pour mener à bon port un projet ou vous souhaitez mieux
                                comprendre vos finances au quotidien et les gérer plus efficacement?
                            </p>
                            <Button text="En savoir plus" linkTo={Routes.gestionDeLiquidites} />
                        </div>
                    </div>
                    <div className={styles.serviceDescription}>
                        <AutresBox />
                        <div>
                            <p>
                                Vous avez un besoin particulier, vous nagez dans un océan de possibilités et vous croyez
                                qu’aucune solution n’existe pour vous?
                            </p>
                            <Button text="En savoir plus" linkTo={Routes.autresServices} />
                        </div>
                    </div>
                </div>
            </PageRow>
            <PageRow backgroundImage={waveBackground}>
                <div className={styles.process}>
                    <h2>Notre processus</h2>
                    <div>
                        <span>
                            <p>Première rencontre</p>
                        </span>
                        <span>
                            <p>Analyse du dossier</p>
                        </span>
                        <span>
                            <p>Présentation de solutions</p>
                        </span>
                        <span>
                            <p>Délai de traitement</p>
                        </span>
                        <span>
                            <p>Livraison et suivi</p>
                        </span>
                    </div>
                </div>
            </PageRow>
            <PageRow fullWidth>
                <ColorStripe
                    content={<p>Vivacité d'esprit - Crédibilité - Générosité - Curiosité</p>}
                    responsiveContent={
                        <p>
                            Vivacité d'esprit <br />
                            Crédibilité <br />
                            Générosité <br />
                            Curiosité
                        </p>
                    }
                />
            </PageRow>
            <div
                id="contact"
                className={styles.container}
                style={{
                    backgroundColor: Colors.lightBlue,
                }}
            >
                <Contact />
            </div>
        </Layout>
    )
}

export default Services
