import React, { ReactNode } from 'react'
import * as styles from './color-stripe.module.scss'
import { useLayoutInformation } from '../../hooks/useLayoutInformation'
import { Colors } from '../../colors'

interface Props {
    backgroundColor?: string
    responsiveContent?: ReactNode
    content: ReactNode
}

const ColorStripe: React.FC<Props> = ({ backgroundColor, responsiveContent, content  }) => {
    const { isMobile } = useLayoutInformation(Colors.lightausBlue)

    return (
        <div
            className={styles.container}
            style={{
                backgroundColor: backgroundColor,
            }}
        >
            {!isMobile && content}
            {isMobile && responsiveContent}
        </div>
    )
}

export default ColorStripe
